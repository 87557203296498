<template slot="global">
  <modal :show.sync="isModal" headerClasses="justify-content-center" :required="true" >
    <h4 slot="header" class="title title-up">
      {{ $t('updatemaxorder') }}
    </h4>
    <div :class="`alert-danger`" class="alert alert-dismissible fade show" v-if="modal_alert">
      <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close" @click.prevent="modal_alert = false">
        <i class="nc-icon nc-simple-remove"></i>
      </button>
      <span></span>
    </div>
    <div class="row">
        <div class="col-md-6">
          <h6>{{ $t('initial_balance') }}</h6>{{ form_data.balance }}
        </div>

        <div class="col-md-6">
          <h6>{{ $t('initial_equity') }}</h6>{{ form_data.equity }}
        </div>
        <div class="col-md-12 mt-4">

        </div>
        <div class="col-md-6">
          <label> {{ $t('max_balance_order_calc_label') }}</label>
          <fg-input replace="[^0-9]" type="number" min="0"  v-model="form_data.max_balance_order_number_calc"></fg-input>
        </div>

        <div class="col-md-6">
          <label> {{ $t('max_credit_order_calc_label') }}</label>
          <fg-input replace="[^0-9]" type="number" min="0"  v-model="form_data.max_credit_order_number_calc"></fg-input>
        </div>
    </div>
    <template slot="footer">
      <div class="py-3 mx-auto" v-if="modalinprogress">
        <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
      </div>
      <div class="left-side" v-if="modalinprogress === false">
        <p-button @click.prevent="confirmUpMaxOrder" link>{{ $t('upentrybtn') }}</p-button>
      </div>
      <div class="divider" v-if="modalinprogress === false"></div>
      <div class="right-side" v-if="modalinprogress === false">
        <p-button type="danger" @click.prevent="closeModal" link>{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/UIComponents/Modal.vue";
import {max} from "d3";

export default {
  name: 'FollowerAdminModal',
  components: {Modal},
  props: {
    data_row: {
        type: Object,
    },
    isModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal_alert: false,
      modalinprogress: false,
      form_data: {
        balance: 0,
        equity: 0,
        max_credit_order_number_calc: 0,
        max_balance_order_number_calc: 0
      }
    }
  },
  methods: {
    edit(row) {
      this.form_data.balance = row.begin_balance
      this.form_data.equity = row.begin_equity
      this.form_data.max_credit_order_number_calc = parseInt(row.max_credit_order_number)
      this.form_data.max_balance_order_number_calc = parseInt(row.max_balance_order_number)
    },
    confirmUpMaxOrder() {
      if (
          isNaN(this.form_data.max_credit_order_number_calc) ||
          this.form_data.max_credit_order_number_calc === '' ||
          isNaN(this.form_data.max_balance_order_number_calc) ||
          this.form_data.max_balance_order_number_calc === ''
        )
      {
        return this.$toast.warning(this.$t('emptyfields'))
      }
      this.modalinprogress = true;
      const pjson = {
        "max_credit_order_number_calc": this.form_data.max_credit_order_number_calc,
        "max_balance_order_number_calc": this.form_data.max_balance_order_number_calc,
      };
      this.$upMaxOrder(pjson, this.data_row.follower_id)
        .then(this.successUpHandler, this.errorUpHandler);
    },
    successUpHandler(resp) {
      if (!resp.success) {
        this.$toast.warning(this.$t(resp.msg))
        this.modalinprogress = false;
        return
      }
      this.$toast.success(this.$t('SUCCESS_DONE'))
      this.closeModal();
      this.$emit('success-up-handler');
    },
    errorUpHandler(resp) {
      this.modalinprogress = false;
      if (resp.data.msg) {
        this.$toast.error(this.$t(resp.data.msg))
      }
    },
    closeModal() {
      this.modalinprogress = false;
      this.$emit('on-close-modal')
    },
  },
  watch: {
    data_row: {
      handler(val, oldVal) {
        if (val != null) {
          this.edit(val);
        }
      },
      deep: true
    },
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  },
}
</script>
