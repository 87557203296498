<template>
  <module
    ref="module"
    id="followersadmin"
    titleIcon="fa fa-users"
    :title="makeTitle"
    apiUrl="ACCAPIURL_V4"
    :url="`/accounts/followers`"
    :filters.sync="filters"
    :visibilityFields.sync="tableColumns"
    tableLoading="true"
    @on-select="passCurrentRow"
  >

    <template slot="table-columns">
      <el-table-column
        :label="$t('optionstxt')"
        width="100px"
      >
        <template scope="{row}">
          <p-button type="primary" link @click="openModal" v-if="row.date_ready && row.bal_equ_ready">
            <i class="fa fa-pencil" style="font-size: x-large"></i>
          </p-button>
          <el-tooltip :content="`${$t('data_not_extracted_yet')}`" placement="top" v-else>
             <p-button type="default" link>
              <i class="fa fa-hourglass-half" style="font-size: x-large"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
        v-if="tableColumns.account.isVisible"
        :prop="tableColumns.account.prop"
        :label="tableColumns.account.label"
        :width="tableColumns.account.minWidth"
        sortable
      />

      <el-table-column
        v-if="tableColumns.broker.isVisible"
        :prop="tableColumns.broker.prop"
        :label="tableColumns.broker.label"
        :width="tableColumns.broker.minWidth"
        sortable
      />

      <el-table-column
        v-if="tableColumns.mat_server.isVisible"
        :prop="tableColumns.mat_server.prop"
        :label="tableColumns.mat_server.label"
        :width="tableColumns.mat_server.minWidth"
        sortable
      />

      <el-table-column
        v-if="tableColumns.date_follow.isVisible"
        :prop="tableColumns.date_follow.prop"
        :label="tableColumns.date_follow.label"
        :width="tableColumns.date_follow.minWidth"
        align="center"
        sortable
      >
        <template slot-scope="{row}">
          <span class="text-center" v-if="row.date_follow === null">-</span>
          <span v-else>{{followDate(row.date_follow)}}</span>
        </template>

      </el-table-column>

      <el-table-column
        v-if="tableColumns.begin_equity.isVisible"
        :prop="tableColumns.begin_equity.prop"
        :label="tableColumns.begin_equity.label"
        :width="tableColumns.begin_equity.minWidth"
        align="right"
        sortable
      >
        <template slot-scope="{row}">
          <span>{{parseFloat(row.begin_equity).toFixed(2)}}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="tableColumns.begin_balance.isVisible"
        :prop="tableColumns.begin_balance.prop"
        :label="tableColumns.begin_balance.label"
        :width="tableColumns.begin_balance.minWidth"
        align="right"
        sortable
      >
        <template slot-scope="{row}">
          <span>{{parseFloat(row.begin_balance).toFixed(2)}}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="tableColumns.pay_performance.isVisible"
        :prop="tableColumns.pay_performance.prop"
        :label="tableColumns.pay_performance.label"
        :width="tableColumns.pay_performance.minWidth"
        align="center"
        sortable
      >

        <template slot-scope="{row}">
          <span v-if="row.pay_performance === 0">{{$t('notxt')}}</span>
          <span v-else>{{$t('yestxt')}}</span>
        </template>

      </el-table-column>

      <el-table-column
        v-if="tableColumns.max_balance_order_number.isVisible"
        :prop="tableColumns.max_balance_order_number.prop"
        :label="tableColumns.max_balance_order_number.label"
        :width="tableColumns.max_balance_order_number.minWidth"
        align="center"
        sortable
      >

        <template slot-scope="{row}">
          <span v-if="row.max_balance_order_number !== undefined && row.max_balance_order_number !== 'None' && row.max_balance_order_number !== null">{{row.max_balance_order_number_calc}}</span>
          <span v-else>-</span>
        </template>

      </el-table-column>
      <el-table-column
        v-if="tableColumns.max_credit_order_number.isVisible"
        :prop="tableColumns.max_credit_order_number.prop"
        :label="tableColumns.max_credit_order_number.label"
        :width="tableColumns.max_credit_order_number.minWidth"
        align="center"
        sortable
      >
        <template slot-scope="{row}">
          <span v-if="row.max_credit_order_number !== undefined && row.max_credit_order_number !== 'None' && row.max_credit_order_number !== null">{{row.max_credit_order_number_calc}}</span>
          <span v-else>-</span>
        </template>

      </el-table-column>

    </template>

    <template slot="global">
      <follower-admin-modal
        :data_row="selectedRow"
        :isModal="showModal"
        @on-close-modal="closeModal"
        @success-up-handler="refreshList"/>
    </template>

     <template slot="filter-field">
        <fg-input v-model="filters.perAccount" :label="$t('account')"></fg-input>
        <fg-input v-model="filters.perBroker" :label="$t('broker')"></fg-input>
        <fg-input v-model="filters.perMatServer" :label="$t('matServer')"></fg-input>

         <el-form v-model="filters" label-position="top">
            <el-form-item :label="$t('payperformance')" label-width="60px"  style="width: 40%">
              <el-select v-model="filters.perPayPerformance">
               <el-option
                 v-for="s in formSelectType"
                 :selected="s.key === 'pay_yes'"
                 :key="s.key"
                 :label="s.value"
                 :value="s.key">
               </el-option>
             </el-select>
            </el-form-item>
            <el-form-item :label="$t('maxordernumalt')" label-width="60px"  style="width: 40%">
              <el-select v-model="filters.perMaxOrder">
               <el-option
                 v-for="s in formSelectMaxOrder"
                 :selected="s.key === 'filled'"
                 :key="s.key"
                 :label="s.value"
                 :value="s.key">
               </el-option>
             </el-select>
            </el-form-item>
         </el-form>
     </template>

  </module>
</template>

<script>

import FollowerAdminModal from "@/components/Dashboard/Views/Dashboard/Accounts/FollowerAdmin/FollowerAdminModal.vue";
import Vue from "vue";
import {Switch, Form, FormItem, Tooltip} from "element-ui";

const visibility = localStorage.getItem('visibility_items');
const json = visibility ? JSON.parse(visibility) : null;


Vue.use(Switch);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(require('vue-moment'));

export default {
  name: "FollowersAdmin",
  components: {
    FollowerAdminModal,
    [Tooltip.name]: Tooltip
  },
  data() {
    let self = this;
    return {
      showModal: false,
      filters: {
        perAccount: '',
        perBroker: '',
        perMatServer: '',
        perPayPerformance: 'show_all',
        perMaxOrder: 'show_all'
      },
      selectedRow: {},
      tableColumns: {
        account: {
          prop: 'account',
          label: self.$t('account'),
          isVisible: true,
          minWidth: '130px'
        },
        broker: {
          prop: 'broker',
          label: self.$t('broker'),
          isVisible: true,
          minWidth: '200px'
        },
        mat_server: {
          prop: 'mat_server',
          label: self.$t('matServer'),
          isVisible: true,
          minWidth: '130px'
        },
        date_follow: {
          prop: 'date_follow',
          label: self.$t('fallow_date'),
          isVisible: true,
          minWidth: '135px'
        },
        begin_equity: {
          prop: 'begin_equity',
          label: self.$t('equitybefore'),
          isVisible: true,
          minWidth: '200px',
        },
        begin_balance: {
          prop: 'begin_balance',
          label: self.$t('balancebefore'),
          isVisible: true,
          minWidth: '200px',
        },
         max_balance_order_number: {
          prop: 'max_balance_order_number_calc',
          label: self.$t('max_balance_order_number_label'),
          isVisible: true,
          minWidth: '170px',
        },
         max_credit_order_number: {
          prop: 'max_credit_order_number_calc',
          label: self.$t('max_credit_order_number_label'),
          isVisible: true,
          minWidth: '170px'
        },
        pay_performance: {
          prop: 'pay_performance',
          label: self.$t('payperformance'),
          isVisible: true,
          minWidth: '200px'
        },
      },
      formSelectType: [
        {
          key: 'show_all',
          value: this.$t('show_all')
        },
        {
          key: 'pay_yes',
          value: this.$t('yestxt')
        },
        {
          key: 'pay_no',
          value: this.$t('notxt')
        },
      ],
      formSelectMaxOrder: [
        {
          key: 'show_all',
          value: this.$t('show_all')
        },
        {
          key: 'filled',
          value: this.$t('filled')
        },
        {
          key: 'unfilled',
          value: this.$t('unfilled')
        }
      ],
    }
  },
  methods: {
    passCurrentRow(module) {
      this.selectedRow = module.selectedRow;
    },
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false;
    },
    refreshList() {
      this.$refs.module.refreshList();
    },
    followDate(date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
  },
  computed: {
    makeTitle() {
      return this.$t('followers_admin')
    }
  }
}
</script>

<style>
.el-form--label-top .el-form-item__label {
  padding: 0 !important;
}
.card label{
  margin-bottom: 0 !important;
}
.el-form-item {
    margin-bottom: 0 !important;
}
</style>
